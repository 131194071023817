import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';
import * as Dialog from '@radix-ui/react-dialog';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing.l};
`;



const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 200;
  inset: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
`;

const Content = styled(Dialog.Content)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  margin-top: 118px;
  width: 834px;
  height: auto;
  padding: 45px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentFooter = styled.div`
  padding-top: 24px;
  display: flex;
  width: 100%;
  gap: 16px;
`;

const CloseButton = styled(Dialog.Close)`
  cursor: pointer;
`;



const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  max-width: 336px;
  gap: 8px;
`;

const Section = styled.div<{ $maxWidth?: string }>`
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth};` : '')}
`;

export const S = {
  HeaderContainer,
  Overlay,
  Content,
  ContentHeader,
  ContentFooter,
  CloseButton,
  ButtonContainer,
  Section,
  ...UIElements,

};
