import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';
import { CreateCustomerUserDto } from '@ansvar-sakerhet/api-client';

export function createUser() {
    const getApi = useApi();
    const queryClient = useQueryClient();

    const mutationFn = async (createCustomer: CreateCustomerUserDto) => {
        const api = await getApi();
        const res = await api.customerUserCreate(createCustomer);
        return res.data;
    };

    const onSuccess = () => {
        toast.success('Användaren har lagts till');
        queryClient.invalidateQueries({ queryKey: ['users'] });
    };

    const onError = () => {
        toast.error('Något gick fel');
    };

    const mutation = useMutation({
        mutationFn,
        onSuccess,
        onError,
    });

    return mutation;
}
