import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';
import { projectsKeys } from '../projects/projects.key';

type Props = {
    orgNumber: string;
    name: string;
    projectId: string;
}
export function createCompany() {
    const getApi = useApi();
    const queryClient = useQueryClient();

    const createCompany = async (props: Props) => {
        const api = await getApi();

        const res = await api.companiesCreate({
            registrationNumber: props.orgNumber,
            name: props.name,
            projectId: props.projectId,
        });

        return res.data;
    };

    const onSuccess = () => {
        toast.success('Order har lagts');
        queryClient.invalidateQueries({ queryKey: projectsKeys.all });
    };

    const onError = () => {
        toast.error('Något gick fel');
    };

    const mutation = useMutation(createCompany, {
        onSuccess,
        onError,
    });

    return mutation;
}
