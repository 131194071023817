import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';


export function useUsers() {
  const getApi = useApi();

  const getClient = async () => {
    const api = await getApi();

    const res = await api.customerUserGetUsersInMyCompany();
    return res.data;
  };

  const query = useQuery({
    queryKey: ['users'],
    queryFn: getClient,
    keepPreviousData: false,
  });

  return {
    ...query,
  };
}
