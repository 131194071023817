import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';

export function useGetCompanyStatistics(controlId: string) {
  const getApi = useApi();

  const getControl = async () => {
    const api = await getApi();

    const res = await api.companyStatisticsFindAll(controlId, 'none');
    return res.data;
  };

  const query = useQuery({
    queryFn: getControl,
  });

  return query;
}
