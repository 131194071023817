import { useMemo } from 'react';
import { FindAllControlStatisticsResponse } from '@ansvar-sakerhet/api-client';

export const useProjectStatisticsTableData = (
  statistics: {
    data: FindAllControlStatisticsResponse;
    clientId: string;
    projectName: string;
  }[]
) => {
  const data = useMemo(() => {
    const labelX = statistics.map((stat) => stat.projectName).concat('Landet');
    const dataX1 = statistics.map(
      (stat) => stat.data.project.percentageOfCheckedInAndControlledPersonnel
    );

    dataX1.push(
      statistics[0].data.all.percentageOfCheckedInAndControlledPersonnel
    );

    const dataX2 = statistics.map(
      (stat) => stat.data.project.percentageOfAdditionalPersonnel
    );
    dataX2.push(statistics[0].data.all.percentageOfAdditionalPersonnel);

    const dataX3 = statistics.map(
      (stat) => stat.data.project.percentageOfNotControlledPersonnel
    );
    dataX3.push(statistics[0].data.all.percentageOfNotControlledPersonnel);

    const dataX4 = statistics.map(
      (stat) => stat.data.project.percentageOfPersonnelWithIdCardDeviations
    );
    dataX4.push(
      statistics[0].data.all.percentageOfPersonnelWithIdCardDeviations
    );

    const dataX5 = statistics.map(
      (stat) =>
        stat.data.project.percentageOfPersonnelWithoutSafeConstructionTraining
    );
    dataX5.push(
      statistics[0].data.all
        .percentageOfPersonnelWithoutSafeConstructionTraining
    );

    const dataX6 = statistics.map(
      (stat) => stat.data.project.percentageOfPersonnelWithInvalidIdentification
    );
    dataX6.push(
      statistics[0].data.all.percentageOfPersonnelWithInvalidIdentification
    );

    const dataX7 = statistics.map(
      (stat) => stat.data.project.percentageOfPersonnelWithoutSafetyEquipment
    );
    dataX7.push(
      statistics[0].data.all.percentageOfPersonnelWithoutSafetyEquipment
    );

    const dataX8 = statistics.map(
      (stat) => stat.data.project.percentageOfPersonnelWithoutWorkPermit
    );
    dataX8.push(statistics[0].data.all.percentageOfPersonnelWithoutWorkPermit);

    const dataX9 = statistics.map(
      (stat) => stat.data.project.percentageOfPersonnelWithoutCertificate
    );
    dataX9.push(statistics[0].data.all.percentageOfPersonnelWithoutCertificate);

    const dataX10 = statistics.map(
      (stat) => stat.data.project.percentageOfAcceptedSubcontractors
    );
    dataX10.push(statistics[0].data.all.percentageOfAcceptedSubcontractors);
    // dataX9.push(statistics[0].data.all.percentageOfPersonnelWithoutCertificate);

    return {
      labelX,
      dataX1,
      dataX2,
      dataX3,
      dataX4,
      dataX5,
      dataX6,
      dataX7,
      dataX8,
      dataX9,
      dataX10
    };
  }, [statistics]);

  return data;
};
