import { CustomerUser } from '@ansvar-sakerhet/api-client';
import { id } from 'date-fns/locale';
import { useMemo } from 'react';

export const useUsersTableData = (users: CustomerUser[]) => {

  const columns = useMemo(() => {
    return [
      {
        Header: 'Namn',
        accessor: 'name',
        // iconSrc: (currentSort: SortControls) =>
        //   currentSort === SortControls.ProjectNameAsc
        //     ? 'ArrowDropUp'
        //     : 'ArrowDropDown',
      },
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Åtgärder',
        accessor: 'actions',
      }
    ] as const;
  }, []);

  const data = useMemo(() => {
    return users.map((user) => {
      return {
        name: user.firstName + ' ' + user.lastName,
        id: user.customerUserId,
        actions: 'actions',
      };
    });
  }, [users]);

  return { columns, data };
};
