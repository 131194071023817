import React from 'react';
import { S } from './Users.style';
import { Input } from '../../components/Input';
import { createUser } from '../../traits/api/users/createUser';
import { useNavigate } from 'react-router-dom';

export const NewUser = () => {
    const route = useNavigate();
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [password, setPassword] = React.useState('');

    const createU = createUser();

    const createUserF = () => {
        createU.mutateAsync({
            firstName,
            lastName,
            email,
            password,
            hidden: false,
            clientIds: [],
        }).then(() => {
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setPassword('');
            route('/users');
            
        });
    }

    return (
        <>
            <S.HeaderContainer>
                <S.H3>Lägg till ny användare</S.H3>
            </S.HeaderContainer>
            <S.Form>
                <S.FormRow>
                    <Input value={firstName} onChange={(ev) => {
                        setFirstName(ev.target.value);
                    }} type="text" placeholder="Förnamn" />
                    <Input onChange={(ev) => {
                        setLastName(ev.target.value);
                    }} value={lastName} type="text" placeholder="Efternamn" />
                </S.FormRow>
                <S.FormRow>
                    <Input onChange={(ev) => {
                        setEmail(ev.target.value);
                    }} value={email} type="text" placeholder="E-post" />
                    <Input onChange={(ev) => {
                        setPhone(ev.target.value);
                    }} value={phone} type="text" placeholder="Telefon" />
                </S.FormRow>
                <S.FormRow>
                    <Input onChange={(ev) => {
                        setPassword(ev.target.value);
                    }} value={password} type="password" placeholder="Lösenord" />
                </S.FormRow>
                <S.FormRow>
                    <S.Button onClick={(ev) => {
                        ev.preventDefault();
                        createUserF();
                    }}>Lägg till användare</S.Button>
                </S.FormRow>
            </S.Form>
        </>
    );
};
