import React from 'react';
import { S } from './UserTable.styles';
import { useTable } from 'react-table';
import { useUsersTableData } from './useUsersTableData';
import { CustomerUser } from '@ansvar-sakerhet/api-client';
import { useDeleteUser } from '../../../traits/api/users/useDeleteUser';

export type Props = {
  users: CustomerUser[] | undefined;
  refetch: () => void;
};

export const UserTable = ({
  users,
  refetch,
}: Props) => {
  if (!users) {
    return <div>Loading...</div>;
  }

  const data = useUsersTableData(users);
  const table = useTable(data);

  const deleteUser = useDeleteUser();

  return (
    <S.TableContainer>
      <S.AltTable {...table.getTableProps()}>
        <S.AltTHead>
          {table.headerGroups.map((headerGroup) => (
            <S.AltTHeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.AltTHeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    <S.TableText>{column.render('Header')}</S.TableText>
                  </S.AltTHeadCell>
                );
              })}
            </S.AltTHeadRow>
          ))}
        </S.AltTHead>

        <S.AltTBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.AltTBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {

                  if (cell.column.id === 'actions') {
                    return (
                      <S.AltTBodyCell
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                      >
                        <S.Button onClick={() => {
                          deleteUser.mutateAsync(cell.row.original.id).then(() => {
                            refetch();
                          });
                        }}>
                          Ta bort
                        </S.Button>
                      </S.AltTBodyCell>
                    );
                  }

                  return (
                    <S.AltTBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.AltTBodyCell>
                  );
                })}
              </S.AltTBodyRow>
            );
          })}
        </S.AltTBody>
      </S.AltTable>
    </S.TableContainer >
  );
};
