import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';

export function useDeleteUser() {
    const getApi = useApi();
    const queryClient = useQueryClient();

    const mutationFn = async (userId: string) => {
        const api = await getApi();

        const res = await api.customerUserDeleteCustomerUser({
            customerUserId: userId,
        });

        return res.data;
    };

    const onSuccess = () => {
        queryClient.invalidateQueries(['users']);
        toast.success('Användaren har raderats');
    };

    const onError = () => {
        toast.error('Något gick fel');
    };

    const mutation = useMutation({
        mutationFn,
        onSuccess,
        onError,
    });

    return mutation;
}
