import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';
import { projectsKeys } from './projects.key';
import { UpdateProjectProjectSizeEnum, UpdateProjectProjectTypeEnum } from '@ansvar-sakerhet/api-client';

type CreateProjectMutation = {
    projectName: string,
    projectType: UpdateProjectProjectTypeEnum | undefined,
    projectSize: UpdateProjectProjectSizeEnum | undefined,
}

export function createProject() {
  const getApi = useApi();
    const queryClient = useQueryClient();

    

  const createProject = async (
    props:CreateProjectMutation
  ) => {
    const api = await getApi();

    const res = await api.controlsCreateTest({
        project: props.projectName,
        projectType: props.projectType ? props.projectType as string : undefined,
        projectSize: props.projectSize ? props.projectSize as string : undefined,
    });

    return res.data;
  };

  const onSuccess = () => {
    toast.success('Projekt skapat');
    queryClient.invalidateQueries({ queryKey: projectsKeys.all });
  };

  const onError = () => {
    toast.error('Något gick fel');
  };

  const mutation = useMutation(createProject, {
    onSuccess,
    onError,
  });

  return mutation;
}
