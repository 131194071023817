import React, { useState } from 'react';
import {
  SortControls,
  useGetMyProjects,
} from '../../../../traits/api/projects/useGetMyProjects';
import { ProjectsTable } from './components/ProjectsTable';
import { S } from './AllMyProjects.styles';
import { Loading } from '../../../../components/Loading';
import * as Dialog from '@radix-ui/react-dialog';
import { Input } from '../../../../components/Input';
import { createProject } from '../../../../traits/api/projects/createProject';
import { UpdateProjectProjectSizeEnum, UpdateProjectProjectTypeEnum } from '@ansvar-sakerhet/api-client';

export const AllMyProjects = () => {
  const [page, setPage] = useState(0);

  const [sort, setSort] = useState<SortControls>(SortControls.ProjectNameDesc);
  const [open, setOpen] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [projectType, setProjectType] = useState<
    UpdateProjectProjectTypeEnum | undefined
  >();
  const [projectSize, setProjectSize] = useState<
    UpdateProjectProjectSizeEnum | undefined
  >();
  const [custom, setCustom] = useState(0);

  const { data: projects, count, isLoading } = useGetMyProjects(page, sort);
  const cPrj = createProject();
  const createProjectEvent = () => {
    cPrj.mutate({
      projectName: projectName,
      projectType: projectType,
      projectSize: projectSize,
    });

    setOpen(false);
  }

  if (isLoading || !projects) {
    return <Loading />;
  }

  return (
    <>
      <S.HeaderContainer>
        <S.H3>Projekt</S.H3>
        <S.Button onClick={() => setOpen(true)}>Skapa nytt projekt</S.Button>
        <Dialog.Root open={open}>
          <Dialog.Portal>
            <S.Overlay>
              <S.Content>
                <S.ContentHeader>
                  <Dialog.Title asChild>
                    <S.H4>Skapa nytt projekt</S.H4>
                  </Dialog.Title>

                  <S.CloseButton asChild onClick={() => setOpen(false)}>
                    <S.Icon src="Close" width={20} height={20} />
                  </S.CloseButton>
                </S.ContentHeader>

                <form onSubmit={(e) => {
                  e.preventDefault();
                  createProjectEvent()
                }}>
                  <S.Margin $vertical='m' />
                  <Input value={projectName} onChange={(projectName) => {
                    setProjectName(projectName.target.value);
                  }} placeholder="Skriv projektnamn" />
                  <S.Margin $vertical='m' />
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column', 
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <Dialog.Title asChild>
                      <S.H5>Vilken standard vill du följa?</S.H5>
                    </Dialog.Title>
                    <S.ButtonContainer>
                      <S.Button
                        $outlined={!(projectType === UpdateProjectProjectTypeEnum.Bank)}
                        onClick={(e) => {
                          e.preventDefault()
                          setProjectType(UpdateProjectProjectTypeEnum.Bank)
                        }
                        }
                      >
                        Bankinitativet
                      </S.Button>
                      <S.Button
                        $outlined={!(projectType === UpdateProjectProjectTypeEnum.Csrd)}
                        onClick={(e) => {
                          e.preventDefault()
                          setProjectType(UpdateProjectProjectTypeEnum.Csrd)
                        }
                        }
                      >
                        CSRD
                      </S.Button>
                      <S.Button
                        $outlined={
                          !(projectType === UpdateProjectProjectTypeEnum.Custom)
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setProjectType(UpdateProjectProjectTypeEnum.Custom)
                        }
                        }
                      >
                        Egen
                      </S.Button>
                    </S.ButtonContainer>
                    {projectType === UpdateProjectProjectTypeEnum.Custom && (
                      <S.Section style={{ textAlign: 'center', marginTop: '20px' }}>
                        <S.H5>Antal månader mellan kontroller</S.H5>
                        <S.Margin $vertical="s" />
                        <input
                          style={{}}
                          type="number"
                          onChange={(e) => setCustom(Number(e.target.value))}
                          value={custom}
                        />
                      </S.Section>
                    )}
                    <S.Margin $vertical="s" />
                    {
                      projectType === UpdateProjectProjectTypeEnum.Bank || projectType === UpdateProjectProjectTypeEnum.Csrd ? (
                        <>
                          <Dialog.Title asChild>
                            <S.H5>Hur stort är projektet? (Msek)</S.H5>
                          </Dialog.Title>
                          <S.ButtonContainer>
                            {
                              projectType === UpdateProjectProjectTypeEnum.Csrd && (
                                <S.Button
                                  $outlined={
                                    !(projectSize === UpdateProjectProjectSizeEnum.Small)
                                  }
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setProjectSize(UpdateProjectProjectSizeEnum.Small)
                                  }
                                  }
                                  style={{ width: '100%' }}
                                >
                                  10-50
                                </S.Button>
                              )
                            }

                            <S.Button
                              $outlined={
                                !(projectSize === UpdateProjectProjectSizeEnum.Medium)
                              }
                              onClick={(e) => {
                                e.preventDefault()
                                setProjectSize(UpdateProjectProjectSizeEnum.Medium)
                              }
                              }
                            >
                              {projectType === UpdateProjectProjectTypeEnum.Bank ? '50-500' : '50-250'}
                            </S.Button>
                            <S.Button
                              $outlined={
                                !(projectSize === UpdateProjectProjectSizeEnum.Large)
                              }
                              onClick={(e) => {
                                e.preventDefault()
                                setProjectSize(UpdateProjectProjectSizeEnum.Large)
                              }
                              }
                            >
                              {projectType === UpdateProjectProjectTypeEnum.Bank ? '500+' : '250+'}
                            </S.Button>
                          </S.ButtonContainer>
                        </>
                      ) : null
                    }
                  </div>


                  <S.ContentFooter>
                    <Dialog.Close asChild onClick={() => setOpen(false)}>
                      <S.Button $outlined>Avbryt</S.Button>
                    </Dialog.Close>

                    <S.Button type="submit">Spara</S.Button>
                  </S.ContentFooter>
                </form>
              </S.Content>
            </S.Overlay>
          </Dialog.Portal>
        </Dialog.Root>
      </S.HeaderContainer >
      <ProjectsTable
        projects={projects}
        setSort={setSort}
        sort={sort}
        setPage={setPage}
        page={page}
        numberOfPages={Math.ceil(count / 20)}
      />
    </>
  );
};
