import { FindAllCompanyStatisticsResponse } from '@ansvar-sakerhet/api-client';
import { useMemo } from 'react';


export const useCompanyStatisticsTableData = (
  statistics: FindAllCompanyStatisticsResponse[] | undefined
) => {

  // if(!statistics ||  statistics.length ===  0) {
  //   return {
  //     columns: [],
  //     data: [],
  //   };
  // }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Statistik för underentreprenörer',
        accessor: 'statisticsDescription',
      },
      {
        Header: 'Senaste kontrollen',
        accessor: 'latestControl',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    if (!statistics || statistics.length == 0) return [];
    return [
      {
        statisticsDescription:
          'Antal företag på arbetsplatsen ',
        latestControl: statistics.length,
      },
      {
        statisticsDescription: 'Antal företag på arbetsplatsen som avviker mot föranmälda UE',
        latestControl: statistics.map((statistic) => statistic.company.isPreQualified).filter((a) => !a).length,
      },
      {
        statisticsDescription:
          'Antal svenska företag på arbetsplatsen ',
        latestControl: statistics.map((statistic) => statistic.company.nationality).filter((a) => a === "SE").length
      },
      {
        statisticsDescription: 'Antal utländska företag med anmälningsskyldighet till AMV utstationeringsregister ',
        latestControl: statistics.map((statistic) => statistic.company.hasReportingObligation).filter((a) => a).length
      },
      {
        statisticsDescription: 'Antal utländska företag som ej fullgjort sin anmälningsskyldighet till AMV Utstationeringsregister',
        latestControl: statistics.map((statistic) => statistic.company.isReportingObligationFulfilled).filter((a) => a).length,
      },
    ];
  }, [statistics]);

  return { columns, data };
};
