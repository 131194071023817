import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NotAllowed } from '../../features/exceptions/screens/NotAllowed';
import { NotFound } from '../../features/exceptions/screens/NotFound';
import { Faq } from '../../features/faq';
import { Login } from '../../features/login/screens/Login';
import { AllMyProjects } from '../../features/projects/screens/AllMyProjects';
import { MyProject } from '../../features/projects/screens/MyProject';
import { RedListedCompanies } from '../../features/redListedCompanies';
import { useAuth } from '../hooks/useAuth';
import { LayoutRoute } from './special-routes/LayoutRoute';
import { ProtectedRoute } from './special-routes/ProtectedRoute';
import { ComparableStatistics } from '../../features/comparableStatistics/screens/ComparableStatistics';
import { News } from '../../features/news/screens/News/News';
import { FrameCompanies } from '../../features/frameCompanies/screens/AllCompanies/FrameCompanies';
import { CreateFrameCompany } from '../../features/frameCompanies/screens/CreateCompany/CreateCompany';
import { ImportFrameCompany } from '../../features/frameCompanies/screens/ImportCompanies/ImportCompanies';
import { FrameCompany } from '../../features/frameCompanies/screens/SingleCompany/FrameCompany';
import { Iframes } from '../../features/iframes/Iframes';
import { Users } from '../../features/users/Users';
import { NewUser } from '../../features/users/NewUser';

export const Router: React.FC = () => {
  const { user, isCustomerAdmin, isSuperAdmin } = useAuth();

  return (
    <Routes>
      {!user && <Route path="/" element={<Login />} />}

      {user && !isCustomerAdmin && !isSuperAdmin && (
        <Route path="/inte-tillåtet" element={<NotAllowed />} />
      )}
      {user && (
        <Route element={<ProtectedRoute />}>
          <Route element={<LayoutRoute />}>
            <Route index element={<Navigate to="/nyheter" replace />} />

            <Route path="/nyheter" element={<News />} />
            <Route path="/nyheter/:newsId" element={<News />} />

            <Route path="/mina-projekt" element={<AllMyProjects />} />
            <Route path="/mina-projekt/:projectId" element={<MyProject />} />
            <Route path="/rodlistade-bolag" element={<RedListedCompanies />} />
            <Route path="/rodlistat-sh" element={<Iframes />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/new" element={<NewUser />} />
            <Route path="/ramavtals-foretag" element={<FrameCompanies />} />
            <Route
              path="/ramavtals-foretag/:frameCompanyId"
              element={<FrameCompany />}
            />
            <Route
              path="/ramavtals-foretag/skapa"
              element={<CreateFrameCompany />}
            />
            <Route
              path="/jamforbar-statistik"
              element={<ComparableStatistics />}
            />
            <Route path="/csv-import" element={<ImportFrameCompany />} />

            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
