import React from 'react';
import {S} from './Users.style';
import { useUsers } from '../../traits/api/users/useUsers';
import { UserTable } from './components/UserTable';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../traits/hooks/useAuth';

export const Users = () => {
    const {data, isLoading, refetch} = useUsers();
    const route = useNavigate()
    const { isSuperAdmin } = useAuth();
  
    if(!isSuperAdmin) {
      return <S.H6>Du har inte behörighet att se denna sida</S.H6>;
    }
    
    if(isLoading && data === undefined) {
        return <div>Loading...</div>;
    }

    
  return (
    <>
      <S.HeaderContainer>
        <S.H3>Användare</S.H3>
        <S.Button onClick={() => {
            route('/users/new');
        }}>Lägg till användare</S.Button>
      </S.HeaderContainer>
      <UserTable users={data} refetch={()=> {
            refetch();
      }} />
    </>
  );
};
