import React from 'react';
import { BackgroundControlWithFilesRiskEnum, CompanyWithBackgroundControl } from '@ansvar-sakerhet/api-client';
import { S } from './SubcontractorsTable.styles';
import { useTable } from 'react-table';
import { useSubcontractorsTableData } from './hooks/useSubcontractorsTableData';
import { useEditCompany } from '../../../../../../traits/api/companies/useEditCompany';
import { DownloadReportButton } from './components/DownloadReportButton';

export type Props = {
  subcontractors: CompanyWithBackgroundControl[];
  projectId: string;
  refetch: () => void;
};

export const SubcontractorsTable = ({
  subcontractors,
  projectId,
  refetch,
}: Props) => {
  const data = useSubcontractorsTableData(subcontractors, projectId);
  const table = useTable(data);

  const [rowClicked, setRowClicked] = React.useState('');
  const updateCompany = useEditCompany(
    subcontractors[Number(rowClicked)]
      ? subcontractors[Number(rowClicked)].companyId
      : ''
  );
  const [comment, setComment] = React.useState(
    subcontractors[Number(rowClicked)]
      ? subcontractors[Number(rowClicked)].comment
      : ''
  );

  return (
    <S.TableContainer>
      <S.AltTable {...table.getTableProps()}>
        <S.AltTHead>
          {table.headerGroups.map((headerGroup) => (
            <S.AltTHeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                const headerData = data.columns.filter(
                  ({ accessor }) => accessor === column.id
                )[0];
                const interactiveProps = headerData?.onClick
                  ? { onClick: headerData?.onClick }
                  : {};

                const iconSrc = headerData?.iconSrc?.();

                return (
                  <S.AltTHeadCell
                    {...interactiveProps}
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    <S.TableText>{column.render('Header')}</S.TableText>
                    {iconSrc && <S.CenteredIcon src={iconSrc} />}
                  </S.AltTHeadCell>
                );
              })}
            </S.AltTHeadRow>
          ))}
        </S.AltTHead>

        <S.AltTBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);


            return (
              <>
                <S.AltTBodyRow
                  style={{
                    cursor: 'pointer',
                    borderBottom:
                      rowClicked === row.id ? 'none' : '1px solid black',
                  }}
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                  onClick={() => {
                    setRowClicked(rowClicked === row.id ? '' : row.id);
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <S.AltTBodyCell
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                      >
                        {cell.render('Cell')}
                      </S.AltTBodyCell>
                    );
                  })}
                </S.AltTBodyRow>
                {row.id === rowClicked && (
                  <td colSpan={6}>
                    <S.Flex
                      $flexDir="column"
                      style={{
                        background: 'white',
                        width: '100%',
                        borderBottom: '1px solid black',
                        padding: 15,
                      }}
                    >
                      <S.Flex
                        $width={'100%'}
                        $flexDir="column"
                        $flexGrow={1}
                        $gap={10}
                      >
                        <S.B1 $color="black" $weight={700}>
                          Kommentar
                        </S.B1>
                        <S.Textarea
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                          $height="100px"
                        />
                      </S.Flex>
                      <S.Margin $vertical={'s'} />
                      <S.Flex
                        $flexDir="row"
                        $width={'100%'}
                        $gap={50}
                        $justify={'space-between'}
                      >
                        <S.Button
                          $outlined
                          onClick={() => {
                            updateCompany.mutate({
                              isPaused:
                                !subcontractors[Number(row.id)].isPaused,
                            });
                            refetch();
                          }}
                        >
                          {subcontractors[Number(row.id)].isPaused
                            ? 'Aktivera bolag'
                            : 'Pausa bolag'}
                        </S.Button>
                        <S.Button
                          $outlined
                          onClick={() => {
                            updateCompany.mutate({
                              isDeleted:
                                !subcontractors[Number(row.id)].isDeleted,
                            });
                            refetch();
                          }}
                        >
                          {subcontractors[Number(row.id)].isDeleted
                            ? 'Återställ bolag'
                            : 'Välj bort bolag'}
                        </S.Button>
                        <S.Button
                          onClick={() => {
                            updateCompany.mutate({
                              comment,
                            });
                            refetch();
                          }}
                        >
                          Spara
                        </S.Button>
                      </S.Flex>
                      <S.Margin $vertical={'s'} />
                      <S.Flex $flexDir="column" $gap={10} style={{width: '100%'}}>
                        <S.B1 $color="black" $weight={700}>
                          Alla bakgrundskontroller
                        </S.B1>
                        <S.Flex $flexDir="column" $gap={20} style={{width: '100%'}}>
                            {subcontractors[Number(row.id)].backgroundControls.map(
                              (backgroundControl, i) => (
                                <S.Flex $flexDir='row'  key={i} style={{width: '100%', gap: 20, padding: 10, alignItems: 'center'}}>
                                  <S.B1>{backgroundControl.createdAt.split('T')[0]}</S.B1>
                                  <DownloadReportButton
                                    downloadUrl={backgroundControl.backgroundControlId}
                                    risk={backgroundControl.risk}
                                  />
                                </S.Flex>
                              )
                            )}
                        </S.Flex>
                      </S.Flex>
                    </S.Flex>
                  </td>
                )}
              </>
            );
          })}
        </S.AltTBody>
      </S.AltTable>
    </S.TableContainer>
  );
};
